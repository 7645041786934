import { FormLayout, Modal, Select } from "@shopify/polaris";
import React, { useState } from "react";

import PropTypes from "prop-types";
import positions from "../utils/positions";
import { withPositionContext } from "../contexts/PositionContext";

const ManualSelectionModal = ({
  active,
  onClose,
  activeMap,
  setManualPosition,
}) => {
  const longitudinal = positions[activeMap].longitudinal;
  const lateral = positions[activeMap].lateral;

  const longitudinalOptions = longitudinal.map((position) => {
    return {
      label: position,
      value: position,
    };
  });
  const lateralOptions = lateral.map((position) => {
    return {
      label: position,
      value: position,
    };
  });

  const [selectedLong, setSelectedLong] = useState(
    longitudinalOptions[0].value
  );
  const [selectedLat, setSelectedLat] = useState(lateralOptions[0].value);

  const onConfirm = () => {
    setManualPosition(`${selectedLong}:${selectedLat}`);
    onClose();
  };

  return (
    <Modal
      open={active}
      onClose={onClose}
      title="Manual selection"
      instant
      primaryAction={{
        content: `Select ${selectedLong}:${selectedLat}`,
        onAction: onConfirm,
      }}
      secondaryActions={[
        {
          content: "Neeevermind",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <Select
            label="Longitudinal"
            value={selectedLong}
            onChange={setSelectedLong}
            options={longitudinalOptions}
          />
          <Select
            label="Lateral"
            value={selectedLat}
            onChange={(val) => setSelectedLat(parseInt(val, 10))}
            options={lateralOptions}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

ManualSelectionModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activeMap: PropTypes.string,
};

export default withPositionContext(ManualSelectionModal);
