import React, { useEffect, useState } from "react";
import positions, { maxPositions } from "../utils/positions";

import chooser from "../utils/chooser";
import ls from "local-storage";

const Context = React.createContext();

const PositionContext = ({ children }) => {
  const [activeMap, setActiveMap] = useState("Verdansk");
  const [burnedPositions, setBurnedPositions] = useState([]);
  const [activePosition, setActivePosition] = useState("");

  /**
   * Grab burned positions from local storage
   */
  useEffect(() => {
    const newActiveMap = ls.get("active-map") || Object.keys(positions)[0];
    const newBurnedPositions = ls.get(`burned-positions-${newActiveMap}`);

    setBurnedPositions(newBurnedPositions || []);
    setActiveMap(newActiveMap);
  }, []);

  /**
   * Keep active position up to date
   */
  useEffect(() => {
    setActivePosition(burnedPositions[burnedPositions.length - 1]);
  }, [burnedPositions]);

  /**
   * Get a new position
   * @param {String} map
   */
  const getPosition = () => {
    let newPosition = chooser(activeMap);

    while (burnedPositions.includes(newPosition)) {
      newPosition = chooser(activeMap);

      // Reset positions if we've gone through them all
      if (burnedPositions.length >= maxPositions[activeMap]) {
        resetPositions();
        break;
      }
    }

    setBurnedPositions((prev) => [...prev, newPosition]);
    ls.set(`burned-positions-${activeMap}`, [...burnedPositions, newPosition]);
  };

  /**
   * Set a manual position
   * @param {String} new position (C:2)
   */
  const setManualPosition = (newPosition) => {
    setBurnedPositions([...burnedPositions, newPosition]);
  };

  /**
   * Set a new map
   * @param {String} newMap
   */
  const setMap = (newActiveMap) => {
    const newBurnedPositions = ls.get(`burned-positions-${newActiveMap}` || []);

    ls.set("active-map", newActiveMap);
    setActiveMap(newActiveMap);
    setBurnedPositions(newBurnedPositions);
  };

  /**
   * Reset positions
   */
  const resetPositions = () => {
    setBurnedPositions([]);
    ls.set(`burned-positions-${activeMap}`, []);
  };

  return (
    <Context.Provider
      value={{
        getPosition,
        burnedPositions,
        activePosition,
        setMap,
        activeMap,
        resetPositions,
        setManualPosition,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const withPositionContext = (Rendered) => (props) => {
  return (
    <Context.Consumer>
      {(value) => <Rendered {...props} {...value} />}
    </Context.Consumer>
  );
};

export default PositionContext;
