import "firebase/auth";

import React, { Component, createContext } from "react";

import firebase from "firebase";

const Context = createContext();
const provider = new firebase.auth.GoogleAuthProvider();

class UserContext extends Component {
  state = {
    fetched: false,
    uuid: null,
  };

  componentDidMount = async () => {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ fetched: true, uuid: user?.uid || null });
    });
  };

  login = () => {
    firebase.auth().signInWithPopup(provider);
  };

  logout = async () => {
    await firebase.auth().signOut();
  };

  render = () => {
    const { children } = this.props;

    return (
      <Context.Provider
        value={{
          login: this.login,
          logout: this.logout,
          ...this.state,
        }}
      >
        {children}
      </Context.Provider>
    );
  };
}

const withUserContext = (Rendered) => (props) => {
  return (
    <Context.Consumer>
      {(value) => {
        return <Rendered {...props} {...value} />;
      }}
    </Context.Consumer>
  );
};

export { withUserContext, Context, UserContext as default };
