import "firebase/auth";

import React, { Component, createContext } from "react";

import { base } from "../utils/firebase";
import { withUserContext } from "./UserContext";

const Context = createContext();

class StatsContext extends Component {
  state = {
    fetched: false,
    stats: {},
  };

  syncState = () => {
    const { fetched } = this.state;
    const { uuid } = this.props;

    if (fetched || !uuid) return null;

    this.setState({ fetched: true });

    base.syncState(`/users/${uuid}/stats`, {
      context: this,
      state: "stats",
    });
  };

  componentWillUnmount = () => {
    const { uuid } = this.state;

    base.removeBinding(`/users/${uuid}/stats`);
  };

  submitStats = (map, zone, position, kills) => {
    const { stats } = this.state;

    if (stats?.[map]?.[zone]) {
      this.setState({
        stats: {
          ...stats,
          [map]: {
            ...stats[map],
            [zone]: [
              ...stats[map][zone],
              {
                position: parseInt(position, 10),
                kills: parseInt(kills, 10),
                at: `${new Date()}`,
              },
            ],
          },
        },
      });
    } else {
      this.setState({
        stats: {
          ...stats,
          [map]: {
            ...stats[map],
            [zone]: [
              {
                position: parseInt(position, 10),
                kills: parseInt(kills, 10),
                at: `${new Date()}`,
              },
            ],
          },
        },
      });
    }
  };

  getAverage = (map, zone) => {
    const mapStats = this.state.stats?.[map];
    const zoneStats = mapStats?.[zone];

    if (!!zoneStats?.length) {
      return this.getZoneSummary(zoneStats);
    } else return null;
  };

  getZoneSummary = (zoneStat) => {
    const positions = zoneStat.map((zoneStat) => zoneStat.position);
    const positionSum = positions.reduce((a, b) => a + b);
    const averagePosition =
      Math.round((positionSum / positions?.length).toFixed(0) * 100) / 100;
    const kills = zoneStat.map((zoneStat) => zoneStat.kills);
    const killsSum = kills.reduce((a, b) => a + b);
    const averageKills = Math.round((killsSum / kills?.length) * 100) / 100;
    const games = zoneStat.length;

    return {
      games,
      numPositions: positions.length,
      positionSum,
      averagePosition,
      numKills: kills.length,
      killsSum,
      averageKills,
      pb: this.getPb(zoneStat),
    };
  };

  getMapSummary = (map) => {
    const { stats } = this.state;

    if (!stats?.[map]) return null;

    const zones = Object.keys(stats[map]);

    let games = 0;
    let numPositions = 0;
    let positionSum = 0;
    let numKills = 0;
    let killsSum = 0;
    let pb = 0;

    zones.map((zoneKey) => {
      const zone = stats[map][zoneKey];
      const zoneStats = this.getZoneSummary(zone);

      games += zoneStats.games;
      numPositions += zoneStats.numPositions || 1;
      positionSum += zoneStats.positionSum || 0;
      numKills += zoneStats.numKills || 1;
      killsSum += zoneStats.killsSum || 0;

      if (zoneStats.pb > pb) pb = zoneStats.pb;

      return zone;
    });

    const averagePosition =
      Math.round((positionSum / numPositions) * 100) / 100;
    const averageKills = Math.round((killsSum / numKills) * 100) / 100;

    return {
      games,
      averagePosition,
      averageKills,
      pb,
    };
  };

  getPb = (zoneStat) => {
    if (!!zoneStat?.length) {
      const kills = zoneStat.map((zoneStat) => zoneStat.kills);

      return Math.max(...kills);
    } else return null;
  };

  getHistory = (map, zone) => {
    const mapStats = this.state.stats?.[map];
    const zoneStats = mapStats?.[zone];

    return !!zoneStats?.length ? zoneStats : null;
  };

  componentDidMount = () => this.syncState();
  componentDidUpdate = () => this.syncState();

  render = () => {
    const { children } = this.props;
    const { stats } = this.state;

    return (
      <Context.Provider
        value={{
          stats,
          submitStats: this.submitStats,
          getAverage: this.getAverage,
          getHistory: this.getHistory,
          getMapSummary: this.getMapSummary,
        }}
      >
        {children}
      </Context.Provider>
    );
  };
}

const withStatsContext = (Rendered) => (props) => {
  return (
    <Context.Consumer>
      {(value) => {
        return <Rendered {...props} {...value} />;
      }}
    </Context.Consumer>
  );
};

StatsContext = withUserContext(StatsContext);

export { withStatsContext, Context, StatsContext as default };
