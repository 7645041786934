import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Card,
  FooterHelp,
  FormLayout,
  Layout,
  Link,
  Page,
  TextField,
} from "@shopify/polaris";
import React, { useState } from "react";
import positions, { verdanskDescriptions } from "../utils/positions";

import { FlagMajor } from "@shopify/polaris-icons";
import HistoryTable from "../components/HistoryTable";
import ManualSelectionModal from "../components/ManualSelectionModal";
import StatBadge from "../components/StatBadge";
import { withPositionContext } from "../contexts/PositionContext";
import { withStatsContext } from "../contexts/StatsContext";
import { withUserContext } from "../contexts/UserContext";

const disabledMaps = ["Rebirth"];

const Home = ({
  burnedPositions,
  activePosition,
  getPosition,
  activeMap,
  setMap,
  resetPositions,
  login,
  logout,
  uuid,
  fetched,
  submitStats,
  getAverage,
  getHistory,
  getMapSummary,
}) => {
  const [manualSelection, setManualSelection] = useState(false);
  const [statPosition, setStatPosition] = useState();
  const [statKills, setStatKills] = useState();
  const [findSpotDisabled, setFindSpotDisabled] = useState(false);

  const handleManualSelection = () => setManualSelection(!manualSelection);

  const mapSummary = uuid && fetched && getMapSummary(activeMap);
  const availableMaps = Object.keys(positions);
  const actionableMaps = availableMaps
    .map((map) => {
      if (map !== activeMap) {
        return {
          content: disabledMaps.includes(map) ? `${map} (Coming soon!)` : map,
          onAction: () => setMap(map),
          disabled: disabledMaps.includes(map),
        };
      } else return null;
    })
    .filter((map) => map);

  const handleGetPosition = () => {
    getPosition();

    if (uuid) {
      setFindSpotDisabled(true);
    }
  };

  const handleSubmitAndNewSpot = () => {
    submitStats(activeMap, activePosition, statPosition, statKills);
    getPosition();
    setStatPosition();
    setStatKills();
  };

  const handleSkipAndNewSpot = () => {
    getPosition();
    setStatPosition();
    setStatKills();
  };

  const mapZoneStats = getAverage(activeMap, activePosition);

  return (
    <Page
      title="Warzone Noodle"
      subtitle="A Warzone drop spot provider based on real data from players like you."
      primaryAction={
        <Button
          disabled
          connectedDisclosure={{
            actions: actionableMaps,
          }}
        >
          {activeMap}
        </Button>
      }
      secondaryActions={
        fetched
          ? uuid
            ? [
                {
                  content: "Logout",
                  onAction: logout,
                },
              ]
            : [
                {
                  content: "Login",
                  onAction: login,
                },
              ]
          : {}
      }
    >
      <ManualSelectionModal
        active={manualSelection}
        onClose={handleManualSelection}
      />

      <Layout>
        {mapSummary && (
          <Layout.Section>
            <Card title={`${activeMap} stats`}>
              <Card.Section>
                <ButtonGroup>
                  {mapSummary?.games > 0 && (
                    <Badge status="info">
                      Random drops: {mapSummary?.games}
                    </Badge>
                  )}

                  {mapSummary?.averagePosition > 0 && (
                    <StatBadge
                      id="averagePosition"
                      label="Average position"
                      value={mapSummary.averagePosition}
                    />
                  )}

                  {mapSummary?.averageKills > 0 && (
                    <StatBadge
                      id="averageKills"
                      label="Average kills"
                      value={mapSummary.averageKills}
                    />
                  )}

                  {mapSummary?.pb > 0 && (
                    <StatBadge
                      id="pb"
                      label="Personal best"
                      value={mapSummary.pb}
                    />
                  )}
                </ButtonGroup>
              </Card.Section>
            </Card>
          </Layout.Section>
        )}
        <Layout.Section oneHalf>
          <Card
            title="Drop spot"
            actions={[
              {
                content: "Find a spot",
                onAction: handleGetPosition,
                disabled: findSpotDisabled,
              },
              {
                content: "Manual selection",
                onAction: handleManualSelection,
              },
              {
                content: "Reset spots",
                onAction: resetPositions,
                disabled: !burnedPositions?.length,
              },
            ]}
          >
            {activePosition ? (
              <>
                <Card.Section>
                  <Banner icon={FlagMajor} status="info" title={activePosition}>
                    {verdanskDescriptions[activePosition]}
                  </Banner>
                </Card.Section>

                {fetched && uuid ? (
                  <Card.Section
                    title={`How was ${verdanskDescriptions[activePosition]} (${activePosition})?`}
                  >
                    <FormLayout>
                      <TextField
                        type="number"
                        inputMode="numeric"
                        label="What position did you/your team get?"
                        max={150}
                        value={statPosition}
                        onChange={setStatPosition}
                      />
                      <TextField
                        type="number"
                        inputMode="numeric"
                        max={75}
                        label="How many kills did you get?"
                        value={statKills}
                        onChange={setStatKills}
                      />
                      <ButtonGroup>
                        <Button
                          onClick={() => handleSubmitAndNewSpot()}
                          disabled={!statPosition || !statKills}
                        >
                          Submit and new spot
                        </Button>
                        <Button onClick={() => handleSkipAndNewSpot()}>
                          Skip
                        </Button>
                      </ButtonGroup>
                    </FormLayout>
                  </Card.Section>
                ) : null}
              </>
            ) : (
              <Card.Section title="No spot yet" />
            )}
          </Card>
        </Layout.Section>

        {activeMap && activePosition && (
          <Layout.Section oneHalf>
            <Card title={`${activeMap} ${activePosition} stats`}>
              {mapZoneStats ? (
                <>
                  <Card.Section
                    title={`Your stats for ${verdanskDescriptions[activePosition]}`}
                  >
                    <ButtonGroup>
                      {mapZoneStats?.games > 0 && (
                        <Badge status="info">
                          Random drops: {mapZoneStats?.games}
                        </Badge>
                      )}
                      <StatBadge
                        id="averagePosition"
                        label="Average position"
                        value={mapZoneStats.averagePosition}
                      />
                      <StatBadge
                        id="averageKills"
                        label="Average kills"
                        value={mapZoneStats.averageKills}
                      />
                      <StatBadge
                        id="pb"
                        label="Personal best"
                        value={mapZoneStats.pb}
                      />
                    </ButtonGroup>
                  </Card.Section>

                  <Card.Section title="Zone history" fullWidth>
                    <HistoryTable
                      statHistory={getHistory(activeMap, activePosition)}
                    />
                  </Card.Section>
                </>
              ) : (
                <Card.Section
                  title={
                    fetched && !uuid
                      ? "Login to track your stats!"
                      : "No stats yet"
                  }
                ></Card.Section>
              )}
            </Card>
          </Layout.Section>
        )}
      </Layout>

      <FooterHelp>
        Contact me at{" "}
        <Link external url="mailto:hello@brydoncreative.com">
          hello@brydoncreative.com
        </Link>
      </FooterHelp>
    </Page>
  );
};

export default withUserContext(withPositionContext(withStatsContext(Home)));
