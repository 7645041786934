import positions from "./positions";
import random from "./random";

const chooser = (map) => {
  const lateral = random(positions[map].lateral);
  const longitudinal = random(positions[map].longitudinal);
  const position = `${longitudinal}:${lateral}`;

  return position;
};

export default chooser;
