import { Badge } from "@shopify/polaris";
import React from "react";

const getIdStatus = {
  averagePosition: (value) => {
    if (value <= 10) return "success";
    if (value <= 20) return "warning";
    return "critical";
  },
  averageKills: (value) => {
    if (value >= 10) return "success";
    if (value >= 5) return "warning";
    return "critical";
  },
  pb: (value) => {
    if (value >= 15) return "success";
    if (value >= 5) return "warning";
    return "critical";
  },
};

const StatBadge = ({ id, label, value }) => (
  <Badge status={getIdStatus[id](value)}>
    {label}: {value}
  </Badge>
);

export default StatBadge;
