import { DataTable } from "@shopify/polaris";
import React from "react";

const HistoryTable = ({ statHistory }) => (
  <DataTable
    columnContentTypes={["text", "numeric", "numeric"]}
    headings={["Submitted at", "Position", "Kills"]}
    rows={statHistory.map((row) => [row.at, row.position, row.kills])}
  />
);

export default HistoryTable;
