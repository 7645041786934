import Rebase from "re-base";
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyC7ay7dDdSsFR7fewq07GOdvPootOSbHHw",
  authDomain: "warzone-noodle.firebaseapp.com",
  databaseURL: "https://warzone-noodle-default-rtdb.firebaseio.com",
  projectId: "warzone-noodle",
  storageBucket: "warzone-noodle.appspot.com",
  messagingSenderId: "620507825116",
  appId: "1:620507825116:web:b5de27f5e3fc33cc3a4519",
};

const app = firebase.initializeApp(firebaseConfig);
const base = Rebase.createClass(firebase.database(app));

firebase.analytics();

export { base };
