const Verdansk = {
  lateral: [2, 3, 4, 5, 6, 7, 8],
  longitudinal: ["C", "D", "E", "F", "G", "H"],
};

const verdanskDescriptions = {
  "C:2": "Summit",
  "C:3": "South of Summit",
  "C:4": "West side of Airport",
  "C:5": "Storage",
  "C:6": "Boneyard",
  "C:7": "South of Boneyard",
  "C:8": "Tough it out, bottom-left of the map",
  "D:2": "East of Summit, near the old dam  bridge",
  "D:3": "South of the old dam",
  "D:4": "East side of Airport",
  "D:5": "Superstore",
  "D:6": "Top-end of Train Station",
  "D:7": "West Promenade or Hills",
  "D:8": "Southwest of Hills",
  "E:2": "West of Military",
  "E:3": "South of Military",
  "E:4": "West of TV",
  "E:5": "East side of Factory",
  "E:6": "West side of Hospital",
  "E:7": "East Promenade",
  "E:8": "East side of Hills",
  "F:2": "East Military (Bunkers?)",
  "F:3": "West of Salt Mine",
  "F:4": "TV",
  "F:5": "West of Stadium, south of TV",
  "F:6": "East Hospital or West Downtown",
  "F:7": "Southwest Downtown or Northwest Park",
  "F:8": "South of Park",
  "G:2": "North of Salt Mine",
  "G:3": "Salt Mine",
  "G:4": "Array",
  "G:5": "Stadium",
  "G:6": "Northeast Downtown",
  "G:7": "Southeast Downtown or West Port",
  "G:8": "South Port",
  "H:2": "Squad leaders pick!",
  "H:3": "East of Salt Mine",
  "H:4": "East of Array",
  "H:5": "Lumber",
  "H:6": "Northwest Farmland",
  "H:7": "East Port or Southwest Farmland",
  "H:8": "Prison",
};

const Rebirth = {
  lateral: [2, 3, 4, 5, 6],
  longitudinal: ["D", "E", "F", "G", "H"],
};

const positions = {
  Verdansk,
  Rebirth,
};

const maxPositions = {
  Verdansk: 42,
  Rebirth: 25,
};

export { positions as default, verdanskDescriptions, maxPositions };
