import "./utils/firebase";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { AppProvider } from "@shopify/polaris";
import Home from "./pages/Home";
import PositionContext from "./contexts/PositionContext";
import StatsContext from "./contexts/StatsContext";
import UserContext from "./contexts/UserContext";

const theme = {
  colorScheme: "dark",
};

const Navigator = () => (
  <AppProvider theme={theme}>
    <UserContext>
      <StatsContext>
        <PositionContext>
          <Router>
            <Switch>
              <Route path="/" component={Home} />
            </Switch>
          </Router>
        </PositionContext>
      </StatsContext>
    </UserContext>
  </AppProvider>
);

export default Navigator;
